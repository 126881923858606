'use client';

import type { ReactNode } from 'react';
import { NextIntlClientProvider, useTimeZone } from 'next-intl';

import { useTimezone } from '#hooks/useTimezone';

export interface NextIntlProviderProps {
  messages: any;
  locale: string;
  children: ReactNode;
}

export const NextIntlProvider = ({ messages, locale, children }: NextIntlProviderProps) => {
  const tz = useTimezone();

  return (
    <NextIntlClientProvider messages={messages} locale={locale} timeZone={tz} now={new Date()}>
      {children}
    </NextIntlClientProvider>
  );
};
