'use client';

import React, { useEffect } from 'react';
import Link from 'next/link';

import { Button } from '@zealy/design-system';

import { useCookiePreferences } from '#context/CookiePreferences';
import { usePopup } from '#features/Popups';
import { useDidMount } from '#hooks/useDidMount';

export interface CookieBannerProps {
  title: string;
  description: React.ReactNode;
  acceptLabel: string;
  refuseLabel: string;
}

export const CookieBanner = ({
  title,
  description,
  acceptLabel,
  refuseLabel,
}: CookieBannerProps) => {
  const { isOpen, onOpenChange } = usePopup({ id: 'cookie-banner', priority: 0 });
  const { preferences, setPreferences } = useCookiePreferences();

  const mounted = useDidMount();

  useEffect(() => {
    if (!preferences) onOpenChange(true);
    return () => onOpenChange(false);
  }, [onOpenChange, preferences]);

  if (!mounted || !isOpen) {
    return null;
  }

  const setAllPreferences = (value: boolean) => () => {
    setPreferences?.({
      functional: value,
      analytics: value,
      marketing: value,
    });
    onOpenChange(false);
  };

  return (
    <div className="bg-tertiary p-md shadow-md left-0 md:left-auto md:m-300 bottom-0 right-0 rounded-t-component-md  md:max-w-[500px] md:rounded-b-component-md z-50 fixed">
      <h6 className="title-2 mb-50 text-primary">{title}</h6>
      {description}
      <div className="flex mt-300 gap-100 md:flex-row flex-row-reverse">
        <Button color="cta" onClick={setAllPreferences(true)}>
          {acceptLabel}
        </Button>
        <Button variant="ghost" onClick={setAllPreferences(false)}>
          {refuseLabel}
        </Button>
        <Button variant="ghost" as={Link} href="/consent-preferences">
          Customize
        </Button>
      </div>
    </div>
  );
};
