'use client';

/* eslint-disable no-unused-vars */
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCookie } from 'react-use';

interface Preferences {
  analytics: boolean;
  marketing: boolean;
  functional: boolean;
}

const defaultPreferences: Preferences = {
  analytics: false,
  marketing: false,
  functional: true,
};

const CookiePreferences = createContext<{
  preferences: Preferences | undefined;
  // eslint-disable-next-line no-unused-vars
  setPreferences: ((preferences: Partial<Preferences>) => void) | undefined;
}>({
  preferences: undefined,
  setPreferences: undefined,
});
export const CookiePreferencesProvider = ({ children }: { children: ReactNode }) => {
  const [cookieConfig, setCookieConfig] = useCookie('cookie-config');

  const value = useMemo(() => {
    if (cookieConfig) {
      try {
        const preferences = JSON.parse(cookieConfig);
        return preferences;
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  }, [cookieConfig]);

  return (
    <CookiePreferences.Provider
      value={{
        preferences: value,
        setPreferences: (p: Partial<Preferences>) => {
          const fullPreferences = {
            ...(value || defaultPreferences),
            ...p,
          };
          setCookieConfig(JSON.stringify(fullPreferences), {
            domain: `${window.location.hostname}`,
            expires: 90,
          });
        },
      }}
    >
      {children}
    </CookiePreferences.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useCookiePreferences = () => {
  const result = useContext(CookiePreferences);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
