import { createFlagsmithInstance } from 'flagsmith/isomorphic';

import { envConfig } from '#app/config';

const communitiesFlag = createFlagsmithInstance();

const init = communitiesFlag.init({
  environmentID: envConfig.flagsmith.key,
  cacheFlags: true,
  // if enabled, this property is sending a request to flagsmith server on every flag evaluation, which put a lot of pressure on flagsmith server
  enableAnalytics: envConfig.env === 'production',
  cacheOptions: {
    skipAPI: false,
    ttl: 60 * 1000 * 15, // 15 minute
  },
  defaultFlags: {
    nftConditions: {
      enabled: false,
    },
    nftRewards: {
      enabled: false,
    },
  },
});

export { communitiesFlag, init };
