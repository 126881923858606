'use client';

import { IntercomProvider } from 'react-use-intercom';

import { envConfig } from '#app/config';

export const SupportChatProvider = ({ children }: { children: React.ReactNode }) => (
  <IntercomProvider appId={envConfig.intercom.websiteId} autoBoot={false}>
    {children}
  </IntercomProvider>
);
