'use client';

import { createZealyQueryClient } from '@zealy/queries';

import { envConfig } from '#app/config';

const { ZealyQueryProvider, queryClient } = createZealyQueryClient(
  {
    api: {
      baseURL: envConfig.apiUrl,
    },
    apiV2: {
      baseURL: envConfig.apiV2Url,
    },
  },
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
);

export { ZealyQueryProvider, queryClient };
