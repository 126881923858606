'use client';

import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useSearchParams } from 'next/navigation';

export const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export const getUtmParamsFromCookie = (): Record<string, string> => {
  const cookie = Cookies.get('utm_params');
  if (cookie) {
    return JSON.parse(cookie);
  }
  return {};
};

export const deleteUtmParamsCookie = () => {
  Cookies.remove('utm_params');
};

export const SourceTracker = () => {
  const searchParams = useSearchParams();

  useEffect(() => {
    const extractedParams = {} as Record<string, string>;

    utmParams.forEach(param => {
      const value = searchParams.get(param);
      if (value) {
        extractedParams[param] = value;
      }
    });

    // Store the extracted UTM parameters in a cookie
    if (Object.keys(extractedParams).length > 0) {
      Cookies.set('utm_params', JSON.stringify(extractedParams), { path: '/' });
    }
  }, [searchParams]);

  return null;
};
