// I'm instantiating the ReCaptchaProvider in the client side because of this issue: https://github.com/snelsi/next-recaptcha-v3/issues/135

'use client';

import { ReCaptchaProvider as ReCaptchaProviderLib } from 'next-recaptcha-v3';

import { envConfig } from '#app/config';

export const ReCaptchaProvider = ({ children }: { children: React.ReactNode }) => (
  <ReCaptchaProviderLib reCaptchaKey={envConfig.google.recaptchSiteKey} useEnterprise={true}>
    {children}
  </ReCaptchaProviderLib>
);
