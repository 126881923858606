'use client';

import { useEffect } from 'react';
import Script from 'next/script';

import { useDidMount } from '#hooks/useDidMount';

const API_KEY = '3307bd';

const SCRIPT_URL = 'https://r.wdfl.co/rw.js';

export const InitRewardful = () => {
  const didMount = useDidMount();

  if (!didMount) return null;

  return (
    <>
      <Script src={SCRIPT_URL} data-rewardful={API_KEY}></Script>
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
    </>
  );
};
