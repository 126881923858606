'use client';

import type { ExternalToast } from 'sonner';
import { toast as base, Toaster as SonnerToaster } from 'sonner';

import type { ToastProps } from '@zealy/design-system';
import { Toast } from '@zealy/design-system';

const toastOptions = {
  unstyled: true,
  classNames: {
    toast: 'overflow-hidden rounded-toast',
  },
};

export const Toaster = () => (
  <SonnerToaster theme="dark" className="" toastOptions={toastOptions} />
);

const defaultToast = (
  title: ToastProps['title'],
  options?: Omit<ToastProps, 'title'>,
  data?: ExternalToast | undefined,
): string | number => {
  const toastId = base(
    <Toast
      {...{
        title,
        ...options,
        onDismiss: options?.onDismiss
          ? () => {
              options.onDismiss?.();
              base.dismiss(toastId);
            }
          : () => base.dismiss(toastId),
      }}
    />,
    data,
  );
  return toastId;
};

const error = (
  title: ToastProps['title'],
  options?: Omit<ToastProps, 'title'>,
  data?: ExternalToast | undefined,
) => defaultToast(title, { ...options, variant: 'error' }, data);

const success = (
  title: ToastProps['title'],
  options?: Omit<ToastProps, 'title'>,
  data?: ExternalToast | undefined,
) => defaultToast(title, { ...options, variant: 'success' }, data);

const warning = (
  title: ToastProps['title'],
  options?: Omit<ToastProps, 'title'>,
  data?: ExternalToast | undefined,
) => defaultToast(title, { ...options, variant: 'warning' }, data);

const loading = (
  title: ToastProps['title'],
  options?: Omit<ToastProps, 'title'>,
  data?: ExternalToast | undefined,
) => defaultToast(title, { ...options, variant: 'loading' }, data);

const toast = Object.assign(defaultToast, {
  ...base,
  error,
  loading,
  success,
  warning,
});

export { toast };
