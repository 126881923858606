import { useLayoutEffect, useState } from 'react';

export const useTimezone = () => {
  // next-intl needs a default value otherwhise it throws ENVIRONMENT_FALLBACK => https://linear.app/zealy/issue/A-4326/[error]-environment-fallback
  // https://github.com/amannn/next-intl/blob/5596ae873df9b558a564f7b73da64105da7fb2bb/packages/use-intl/src/react/useTranslationsImpl.tsx#L31
  const [tz, setTz] = useState<string>('Europe/Berlin');

  useLayoutEffect(() => {
    setTz(Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin');
  }, []);

  return tz;
};
