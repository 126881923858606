// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { envConfig } from '#app/config';

datadogLogs.init({
  clientToken: envConfig.datadog.clientToken,
  site: 'zealy.io',
  forwardErrorsToLogs: true,
  // 1% of the traces will be sent to datadog
  sessionSampleRate: 1,
});

datadogRum.init({
  site: 'datadoghq.com',
  env: process.env.NODE_ENV,
  version: '1.0.0',
  defaultPrivacyLevel: 'mask-user-input',
  // Specify URLs to propagate trace headers for connection between RUM and backend trace
  allowedTracingUrls: [{ match: 'https://.*.zealy.io/', propagatorTypes: ['tracecontext'] }],
  applicationId: envConfig.datadog.appId,
  clientToken: envConfig.datadog.clientToken,
  service: 'zealy-webapp',
  sessionSampleRate: envConfig.datadog.sessionSampleRate ?? 0.01, // 0.1% of sessions will be tracked
  sessionReplaySampleRate: envConfig.datadog.sessionReplaySampleRate ?? 0, // Among those 1% tracked session, 100% are screen recorded
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  startSessionReplayRecordingManually: true,
  // 1% of the traces will be sent to datadog
  traceSampleRate: 1,
});

export function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
